import { Grid, Hidden } from "@material-ui/core";
import React from "react";
import HomeTicket from "./hometicket";

import topticket1 from "../../images/home/topticket1.png";
import topticket2 from "../../images/home/topticket2.png";
import topticket3 from "../../images/home/topticket3.png";

type Props = {
  onLoad?: () => void;
};

const TicketArea: React.FC<Props> = (props) => {
  return (
    <>
      <Hidden smUp>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={10}
              sm={8}
              md={4}
              style={{
                margin: "0",
              }}
            >
              <HomeTicket
                src={topticket1}
                alt="workticket"
                width="100%"
                height="auto"
                link="/work"
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={8}
              md={4}
              style={{
                margin: "0",
              }}
            >
              <HomeTicket
                src={topticket2}
                alt="phototicket"
                width="100%"
                height="auto"
                link="/photo"
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={8}
              md={4}
              style={{
                margin: "0",
              }}
            >
              <HomeTicket
                src={topticket3}
                alt="aboutticket"
                width="100%"
                height="auto"
                link="/about"
                onLoad={props.onLoad}
              />
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden smDown>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "750px",
              height: "125px",
            }}
          >
            <HomeTicket
              src={topticket1}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/work"
            />
            <HomeTicket
              src={topticket2}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/photo"
            />
            <HomeTicket
              src={topticket3}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/about"
              onLoad={props.onLoad}
            />
          </div>
        </div>
      </Hidden>
      <Hidden xsDown mdUp>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "480px",
              height: "80px",
            }}
          >
            <HomeTicket
              src={topticket1}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/work"
            />
            <HomeTicket
              src={topticket2}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/photo"
            />
            <HomeTicket
              src={topticket3}
              alt="aboutticket"
              width="33%"
              height="auto"
              link="/about"
              onLoad={props.onLoad}
            />
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default TicketArea;

import React, { useState } from "react";
import BackgroundWrapper from "../sharedComponents/backgroundwrapper";
import PageHeader from "../sharedComponents/PageHeader";
import header from "../../images/work/header_logo_work.png";
import footer from "../../images/work/footer_logo_work.png";
import pattern from "../../images/work/header_work.png";
import titleImg from "../../images/work/work_structure/title_ticket.png";
import styled from "styled-components";

import photo1 from "../../images/work/work_structure/photo_structure1.jpg";
import photo2 from "../../images/work/work_structure/photo_structure2.jpg";
import photo3 from "../../images/work/work_structure/photo_structure3.jpg";
import { Hidden } from "@material-ui/core";
import LoadingSpinner from "../sharedComponents/loadingSpinner";

const WorkStructure = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(true);

  const didLoadImage = () => {
    setSpinnerState(false);
  };
  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{ display: isSpinnerShow ? "none" : "block", height: "100%" }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#45AAB4"
        />
        <Hidden lgUp>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "80%",
              margin: "55px 0px 55px",
              padding: "0px 10% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <PhotoGroup>
              <img
                src={photo1}
                alt="photo1"
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
            <PhotoGroup>
              <img
                src={photo2}
                alt="photo2"
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
            <PhotoGroup>
              <img
                src={photo3}
                alt="photo3"
                onLoad={didLoadImage}
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "60%",
              margin: "55px 0px 55px",
              padding: "0px 20% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <PhotoGroup>
              <img
                src={photo1}
                alt="photo1"
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
            <PhotoGroup>
              <img
                src={photo2}
                alt="photo2"
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
            <PhotoGroup>
              <img
                src={photo3}
                alt="photo3"
                onLoad={didLoadImage}
                style={{ width: "100%", height: "auto" }}
              />
            </PhotoGroup>
          </div>
        </Hidden>
      </div>
    </>
  );
};

const PhotoGroup = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
`;

export default WorkStructure;

import React from "react";
import backgroundImg from "../../images/background.png";

const BackgroundWrapper: React.FC = () => {
  const vh = window.innerHeight;
  return (
    <div
      style={{
        position: "fixed",
        backgroundImage: `url(${backgroundImg})`,
        width: "100%",
        height: "100%",
        zIndex: -1,
        // height: `${vh}px`,
      }}
    ></div>
  );
};

export default BackgroundWrapper;

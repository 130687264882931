import React from "react";
import spinnerImg from "../../images/eggwalker3.gif";

type Props = {
  spinnerState: boolean;
};

const LoadingSpinner: React.FC<Props> = ({ spinnerState }) => {
  return (
    <div
      id="loader-wrapper"
      style={{
        display: spinnerState ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: "rgb(236, 236, 236)",
        position: "fixed",
        textAlign: "center",
      }}
    >
      <img
        src={spinnerImg}
        alt="Now Loading..."
        style={{ width: "150px", height: "auto" }}
      />
    </div>
  );
};

export default LoadingSpinner;

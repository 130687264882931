import React, { useState } from "react";
import ButtonAbout from "../../images/button_about.png";
import ButtonHome from "../../images/button_home.png";
import ButtonMenu from "../../images/button_menu.png";
import ButtonPhoto from "../../images/button_photo.png";
import ButtonWork from "../../images/button_work.png";
import DrawerButtonHome from "../../images/button_home_sp.png";
import DrawerButtonWork from "../../images/button_work_sp.png";
import DrawerButtonPhoto from "../../images/button_photo_sp.png";
import DrawerButtonAbout from "../../images/button_about_sp.png";
import HomeHeader from "./HomeHeader";
import { Drawer, Hidden, IconButton } from "@material-ui/core";
import { useHistory } from "react-router";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";

type Props = {
  header: string;
  footer: string;
  pattern: string;
  color: string;
};

type ButtonProps = {
  src: string;
  rightPos?: string;
  link: string;
  onClick?: () => void;
};

const HeaderButton: React.FC<ButtonProps> = (props) => {
  const { src, rightPos, link, onClick } = props;
  const history = useHistory();
  const handleToPage = () => {
    history.push(link);
  };
  return (
    <ButtonIcon
      src={src}
      alt="icon"
      style={{
        right: rightPos,
        zIndex: 3,
      }}
      onClick={onClick ? onClick : handleToPage}
    />
  );
};

const ButtonIcon = styled.img`
  height: 55px;
  position: fixed;
  top: 0;
  cursor: pointer;
`;

const PageHeader: React.FC<Props> = (props) => {
  const [isMenuOpen, setMenuState] = useState(false);

  const onClickMenu = () => {
    setMenuState(true);
  };

  const history = useHistory();
  const handleToPage = (link: string) => {
    history.push(link);
  };

  return (
    <>
      <HomeHeader
        header={props.header}
        footer={props.footer}
        pattern={props.pattern}
      />
      <Hidden xsDown>
        <HeaderButton src={ButtonHome} rightPos="35%" link="/" />
        <HeaderButton src={ButtonWork} rightPos="25%" link="/work" />
        <HeaderButton src={ButtonPhoto} rightPos="15%" link="/photo" />
        <HeaderButton src={ButtonAbout} rightPos="5%" link="/about" />
      </Hidden>
      <Hidden smUp>
        <HeaderButton
          src={ButtonMenu}
          rightPos="5%"
          link=""
          onClick={onClickMenu}
        />
        <Drawer
          anchor="right"
          open={isMenuOpen}
          onClose={() => {
            setMenuState(false);
          }}
        >
          <div
            style={{
              backgroundColor: props.color,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <IconButton aria-label="delete" onClick={() => setMenuState(false)}>
              <CloseIcon />
            </IconButton>
            <img
              src={DrawerButtonHome}
              alt="home_button"
              onClick={() => handleToPage("/")}
              style={{ width: "70%", marginRight: "5%", marginTop: "5%" }}
            />
            <img
              src={DrawerButtonWork}
              alt="home_button"
              onClick={() => handleToPage("/work")}
              style={{ width: "70%", marginRight: "5%", marginTop: "5%" }}
            />
            <img
              src={DrawerButtonPhoto}
              alt="home_button"
              onClick={() => handleToPage("/photo")}
              style={{ width: "70%", marginRight: "5%", marginTop: "5%" }}
            />
            <img
              src={DrawerButtonAbout}
              alt="home_button"
              onClick={() => handleToPage("/about")}
              style={{ width: "70%", marginRight: "5%", marginTop: "5%" }}
            />
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};

export default PageHeader;

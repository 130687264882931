import React from "react";
import { BrowserRouter } from "react-router-dom";
import PageRouter from "./pageRouter";

const App = () => {
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <PageRouter />
      </BrowserRouter>
    </>
  );
};

export default App;

import React, { useState } from "react";
import header from "../../images/work/header_logo_work.png";
import footer from "../../images/work/footer_logo_work.png";
import pattern from "../../images/work/header_work.png";
import PageHeader from "../sharedComponents/PageHeader";
import WorkTicketArea from "./workTicketArea";
import BackgroundWrapper from "../sharedComponents/backgroundwrapper";
import LoadingSpinner from "../sharedComponents/loadingSpinner";

const Work: React.FC = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(true);

  const didLoadImage = () => {
    setSpinnerState(false);
  };
  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{
          display: isSpinnerShow ? "none" : "block",
          height: "100%",
        }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#45AAB4"
        />
        <WorkTicketArea onLoad={didLoadImage} />
      </div>
    </>
  );
};

export default Work;

import React from "react";
import { Route } from "react-router-dom";
import Home from "./Pages/home/Home";
import About from "./Pages/About";
import Photo from "./Pages/Photo";
import Work from "./Pages/work/Work";
import WorkMedia from "./Pages/workpages/WorkMedia";
import WorkRiver from "./Pages/workpages/WorkRiver";
import WorkFarnsworth from "./Pages/workpages/WorkFarnsworth";
import WorkStructure from "./Pages/workpages/WorkStructure";

const PageRouter = () => {
  return (
    <>
      <switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route
          path="/work"
          render={({ match: { url } }) => (
            <switch>
              <Route exact path={url}>
                <Work />
              </Route>
              <Route exact path={`${url}/media`}>
                <WorkMedia />
              </Route>
              <Route exact path={`${url}/river`}>
                <WorkRiver />
              </Route>
              <Route exact path={`${url}/farnsworth`}>
                <WorkFarnsworth />
              </Route>
              <Route exact path={`${url}/structure`}>
                <WorkStructure />
              </Route>
            </switch>
          )}
        />
        <Route exact path="/photo">
          <Photo />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
      </switch>
    </>
  );
};

export default PageRouter;

import React from "react";
import styled from "styled-components";
import WorkTicket from "./workTicket";

import ticketPicture1 from "../../images/work/picture1.png";
import ticketPicture2 from "../../images/work/picture2.png";
import ticketPicture3 from "../../images/work/picture3.png";
import ticketPicture4 from "../../images/work/picture4.png";
import ticketStub1 from "../../images/work/ticket1.png";
import ticketStub2 from "../../images/work/ticket2.png";
import ticketStub3 from "../../images/work/ticket3.png";
import ticketStub4 from "../../images/work/ticket4.png";
import { Hidden } from "@material-ui/core";

type Props = {
  onLoad?: () => void;
};

const WorkTicketArea: React.FC<Props> = (props) => {
  // const vh = window.innerHeight;
  return (
    <>
      <Hidden mdUp>
        <div
          style={{
            width: "100%",
            margin: "55px 0 55px",
            overflowX: "hidden",
          }}
        >
          <TicketWrapper>
            <WorkTicket
              picSrc={ticketPicture1}
              stubSrc={ticketStub1}
              alt=""
              link="/work/media"
            />
          </TicketWrapper>
          <TicketWrapper>
            <WorkTicket
              picSrc={ticketPicture2}
              stubSrc={ticketStub2}
              alt=""
              link="work/river"
            />
          </TicketWrapper>
          <TicketWrapper>
            <WorkTicket
              picSrc={ticketPicture3}
              stubSrc={ticketStub3}
              alt=""
              link="work/farnsworth"
            />
          </TicketWrapper>
          <TicketWrapper>
            <WorkTicket
              picSrc={ticketPicture4}
              stubSrc={ticketStub4}
              alt=""
              link="work/structure"
              onLoad={props.onLoad}
            />
          </TicketWrapper>
        </div>
      </Hidden>
      <Hidden smDown>
        <div
          style={{
            // height: "calc((var(--vh, 1vh) * 100) - 110px)",
            width: "100%",
            margin: "55px 0 55px",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "90%",
              height: "30%",
              margin: "5% 5% 5%",
              justifyContent: "flex-end",
            }}
          >
            <TicketWrapperLarge>
              <WorkTicket
                picSrc={ticketPicture1}
                stubSrc={ticketStub1}
                alt=""
                link="/work/media"
              />
            </TicketWrapperLarge>
            <TicketWrapperLarge>
              <WorkTicket
                picSrc={ticketPicture2}
                stubSrc={ticketStub2}
                alt=""
                link="/work/river"
              />
            </TicketWrapperLarge>
          </div>
          <div
            style={{
              display: "flex",
              width: "90%",
              height: "30%",
              margin: "5% 5% 5%",
              justifyContent: "flex-end",
            }}
          >
            <TicketWrapperLarge>
              <WorkTicket
                picSrc={ticketPicture3}
                stubSrc={ticketStub3}
                alt=""
                link="/work/farnsworth"
              />
            </TicketWrapperLarge>
            <TicketWrapperLarge>
              <WorkTicket
                picSrc={ticketPicture4}
                stubSrc={ticketStub4}
                alt=""
                link="work/structure"
                onLoad={props.onLoad}
              />
            </TicketWrapperLarge>
          </div>
        </div>
      </Hidden>
    </>
  );
};

const TicketWrapper = styled.div`
  height: 250px;
  width: 86%;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 7%;
  margin-right: 7%;
`;

const TicketWrapperLarge = styled.div`
  height: 250px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
`;

export default WorkTicketArea;

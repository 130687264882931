import React, { useState } from "react";
import BackgroundWrapper from "../sharedComponents/backgroundwrapper";
import PageHeader from "../sharedComponents/PageHeader";
import header from "../../images/work/header_logo_work.png";
import footer from "../../images/work/footer_logo_work.png";
import pattern from "../../images/work/header_work.png";
import titleImg from "../../images/work/work_river/title_ticket.png";
import styled from "styled-components";

import photo1 from "../../images/work/work_river/photo_river1.jpg";
import photo2 from "../../images/work/work_river/photo_river2.jpg";
import photo3 from "../../images/work/work_river/photo_river3.jpg";
import photo4 from "../../images/work/work_river/photo_river4.jpg";
import photo5 from "../../images/work/work_river/photo_river5.jpg";
import photo6 from "../../images/work/work_river/photo_river6.jpg";
import thumbnail from "../../images/work/work_river/thumbnail.jpg";
import { Hidden } from "@material-ui/core";
import LoadingSpinner from "../sharedComponents/loadingSpinner";

const WorkRiver = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(true);

  const didLoadImage = () => {
    setSpinnerState(false);
  };
  const imgMargin = 1.4;

  const PhotoGroup1 = () => {
    return (
      <PhotoGroup>
        <img
          src={photo1}
          alt="photo1"
          style={{ width: "100%", height: "auto" }}
        />
      </PhotoGroup>
    );
  };

  const PhotoGroup2 = () => {
    return (
      <PhotoGroup>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <img
            src={photo2}
            alt="photo2"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo3}
            alt="photo3"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo4}
            alt="photo4"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginRight: `${imgMargin}%`,
            }}
          />
          <img
            src={photo5}
            alt="photo5"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
            }}
          />
        </div>
      </PhotoGroup>
    );
  };

  const PhotoGroup3 = () => {
    return (
      <PhotoGroup>
        <img
          src={photo6}
          alt="photo6"
          onLoad={didLoadImage}
          style={{ width: "100%", height: "auto" }}
        />
      </PhotoGroup>
    );
  };

  const PhotoGroup4 = () => {
    return (
      <>
        <PhotoGroup>
          <img
            src={photo1}
            alt="photo1"
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
        <PhotoGroup>
          <img
            src={photo2}
            alt="photo2"
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
        <PhotoGroup>
          <img
            src={photo3}
            alt="photo3"
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
        <PhotoGroup>
          <img
            src={photo4}
            alt="photo4"
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
        <PhotoGroup>
          <img
            src={photo5}
            alt="photo5"
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
        <PhotoGroup>
          <img
            src={photo6}
            alt="photo6"
            onLoad={didLoadImage}
            style={{ width: "100%", height: "auto" }}
          />
        </PhotoGroup>
      </>
    );
  };

  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{ display: isSpinnerShow ? "none" : "block", height: "100%" }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#45AAB4"
        />
        <Hidden lgUp>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "80%",
              margin: "55px 0px 55px",
              padding: "0px 10% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <Hidden xsDown>
              <PhotoGroup1 />
              <PhotoGroup2 />
              <PhotoGroup3 />
            </Hidden>
            <Hidden smUp>
              <PhotoGroup4 />
            </Hidden>
            <a href="/river.usdz" rel="ar">
              <img
                src={thumbnail}
                alt="ARthumbnail"
                width="100%"
                height="auto"
                style={{ marginTop: "5%", marginBottom: "5%" }}
              />
            </a>

            <iframe
              src="https://view.mylumion.com/?p=x7u6ombb4qcn36qe&embed=1&iframe=true"
              allowFullScreen={true}
              title="lumion"
              sandbox="allow-same-origin allow-scripts"
              frameBorder="0"
              width="100%"
              height="500px"
              style={{ marginTop: "5%", marginBottom: "5%" }}
            ></iframe>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "60%",
              margin: "55px 0px 55px",
              padding: "0px 20% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <PhotoGroup1 />
            <PhotoGroup2 />
            <PhotoGroup3 />
            <a href="/river.usdz" rel="ar">
              <img
                src={thumbnail}
                alt="ARthumbnail"
                width="100%"
                height="auto"
                style={{ marginTop: "5%", marginBottom: "5%" }}
              />
            </a>

            <iframe
              src="https://view.mylumion.com/?p=x7u6ombb4qcn36qe&embed=1&iframe=true"
              allowFullScreen={true}
              title="lumion"
              sandbox="allow-same-origin allow-scripts"
              frameBorder="0"
              width="100%"
              height="500px"
              style={{ marginTop: "5%", marginBottom: "5%" }}
            ></iframe>
          </div>
        </Hidden>
      </div>
    </>
  );
};

const PhotoGroup = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
`;

export default WorkRiver;

import React, { useState } from "react";
import header from "../../images/home/header_logo_home.png";
import footer from "../../images/home/footer_logo_home.png";
import pattern from "../../images/home/header_home.png";
import footerDeco from "../../images/home/footer_home_building.png";
import footerDecoSmall from "../../images/home/footer_home_building2.png";
import footerDecoXSmall from "../../images/home/footer_home_building3.png";
import headerDeco from "../../images/home/ribbon_home.png";
import headerDecoSmall from "../../images/home/ribbon_home2.png";
import headerDecoXSmall from "../../images/home/ribbon_home3.png";
import BackgroundWrapper from "../sharedComponents/backgroundwrapper";
import LoadingSpinner from "../sharedComponents/loadingSpinner";
import { Grid, Hidden } from "@material-ui/core";
import TicketArea from "./ticketArea";
import HomeHeader from "../sharedComponents/HomeHeader";

const Home: React.FC = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(true);

  const didLoadImage = () => {
    setSpinnerState(false);
  };

  const vh = window.innerHeight;

  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{ display: isSpinnerShow ? "none" : "block", height: "100%" }}
      >
        <BackgroundWrapper />
        <HomeHeader header={header} footer={footer} pattern={pattern} />
        <Grid
          container
          spacing={0}
          justify="center"
          alignItems="center"
          style={{
            height: `${vh - 110}px`,
            margin: "55px 0 55px",
          }}
        >
          <Grid item xs={8} sm={12} md={12}>
            <TicketArea onLoad={didLoadImage} />
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Hidden smUp>
            <img
              src={headerDecoXSmall}
              alt="decoribbon"
              style={{ position: "absolute", top: "10%", width: "250px" }}
            />
          </Hidden>
          <Hidden xsDown mdUp>
            <img
              src={headerDecoSmall}
              alt="decoribbon"
              style={{ position: "absolute", top: "13%", width: "420px" }}
            />
          </Hidden>
          <Hidden smDown>
            <img
              src={headerDeco}
              alt="decoribbon"
              style={{ position: "absolute", top: "13%", width: "750px" }}
            />
          </Hidden>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Hidden smUp>
            <img
              src={footerDecoXSmall}
              alt="decobuilding"
              style={{
                position: "fixed",
                bottom: "0",
                width: "250px",
                zIndex: 3,
              }}
            />
          </Hidden>
          <Hidden xsDown mdUp>
            <img
              src={footerDecoSmall}
              alt="decobuilding"
              style={{
                position: "fixed",
                bottom: "0",
                width: "420px",
                zIndex: 3,
              }}
            />
          </Hidden>
          <Hidden smDown>
            <img
              src={footerDeco}
              alt="decobuilding"
              style={{
                position: "fixed",
                bottom: "0",
                width: "750px",
                zIndex: 3,
              }}
            />
          </Hidden>
        </div>
      </div>
    </>
  );
};

export default Home;

import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

type Props = {
  src: string;
  alt: string;
  width: string;
  height: string;
  link: string;
  onLoad?: () => void;
};

const HomeTicket: React.FC<Props> = (props) => {
  const { src, alt, width, height, link, onLoad } = props;
  const history = useHistory();
  const handleToPage = () => {
    history.push(link);
  };

  return (
    <Img
      src={src}
      alt={alt}
      style={{
        width: width,
        height: height,
      }}
      onLoad={onLoad}
      onClick={handleToPage}
    />
  );
};

const Img = styled.img`
  margin: 0;

  filter: drop-shadow(6px 6px 1px rgba(0, 0, 0, 0.3));

  :hover {
    animation: t1 0.1s ease-out;
    animation-fill-mode: forwards;
    transform-origin: left bottom;
    cursor: pointer;
  }

  @keyframes t1 {
    0% {
      transform: rotate(0deg) translateX(0%);
    }
    100% {
      transform: rotate(5deg) translateX(10%);
    }
  }
`;

export default HomeTicket;

import React, { useState } from "react";
import BackgroundWrapper from "../sharedComponents/backgroundwrapper";
import PageHeader from "../sharedComponents/PageHeader";
import header from "../../images/work/header_logo_work.png";
import footer from "../../images/work/footer_logo_work.png";
import pattern from "../../images/work/header_work.png";
import titleImg from "../../images/work/work_media/title_ticket.png";
import styled from "styled-components";

import photo1 from "../../images/work/work_media/photo_media1.jpg";
import photo2 from "../../images/work/work_media/photo_media2.jpg";
import photo3 from "../../images/work/work_media/photo_media3.jpg";
import photo4 from "../../images/work/work_media/photo_media4.jpg";
import photo5 from "../../images/work/work_media/photo_media5.jpg";
import photo6 from "../../images/work/work_media/photo_media6.jpg";
import photo7 from "../../images/work/work_media/photo_media7.jpg";
import photo8 from "../../images/work/work_media/photo_media8.jpg";
import photo9 from "../../images/work/work_media/photo_media9.jpg";
import photo10 from "../../images/work/work_media/photo_media10.jpg";
import photo11 from "../../images/work/work_media/photo_media11.jpg";
import photo12 from "../../images/work/work_media/photo_media12.jpg";
import photo13 from "../../images/work/work_media/photo_media13.jpg";
import photo14 from "../../images/work/work_media/photo_media14.jpg";
import photo15 from "../../images/work/work_media/photo_media15.jpg";
import photo16 from "../../images/work/work_media/photo_media16.jpg";
import { Hidden } from "@material-ui/core";
import LoadingSpinner from "../sharedComponents/loadingSpinner";

const WorkMedia = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(true);

  const didLoadImage = () => {
    setSpinnerState(false);
  };
  const imgMargin = 1.4;
  const PhotoGroup1 = () => {
    return (
      <PhotoGroup>
        <img
          src={photo1}
          alt="photo1"
          style={{ width: "100%", height: "auto" }}
        />
      </PhotoGroup>
    );
  };

  const PhotoGroup2 = () => {
    return (
      <PhotoGroup>
        <img
          src={photo2}
          alt="photo2"
          style={{ width: "100%", height: "auto" }}
        />
      </PhotoGroup>
    );
  };

  const PhotoGroup3 = () => {
    return (
      <PhotoGroup>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <img
            src={photo3}
            alt="photo3"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginRight: `${imgMargin}%`,
            }}
          />
          <div
            style={{
              display: "flex",
              width: `calc(50% - ${imgMargin / 2}%)`,
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <img
              src={photo4}
              alt="photo4"
              style={{
                width: `calc(50% - ${imgMargin}%)`,
                height: "auto",
                marginRight: `${imgMargin * 2}%`,
                marginBottom: `${imgMargin * 2}%`,
              }}
            />
            <img
              src={photo5}
              alt="photo5"
              style={{
                width: `calc(50% - ${imgMargin}%)`,
                height: "auto",
                marginBottom: `${imgMargin * 2}%`,
              }}
            />
            <img
              src={photo6}
              alt="photo6"
              style={{
                width: `calc(50% - ${imgMargin}%)`,
                height: "auto",
                marginRight: `${imgMargin * 2}%`,
              }}
            />
            <img
              src={photo7}
              alt="photo7"
              style={{ width: `calc(50% - ${imgMargin}%)`, height: "auto" }}
            />
          </div>
        </div>
      </PhotoGroup>
    );
  };

  const PhotoGroup4 = () => {
    return (
      <PhotoGroup>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <img
            src={photo8}
            alt="photo8"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo9}
            alt="photo9"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo10}
            alt="photo10"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo11}
            alt="photo11"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo12}
            alt="photo12"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo13}
            alt="photo13"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo14}
            alt="photo14"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
            }}
          />
          <img
            src={photo15}
            alt="photo15"
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
              marginRight: `${imgMargin}%`,
            }}
          />
          <img
            src={photo16}
            alt="photo16"
            onLoad={didLoadImage}
            style={{
              width: `${(100 - imgMargin * 2) / 3}%`,
              height: "auto",
            }}
          />
        </div>
      </PhotoGroup>
    );
  };

  const PhotoGroup5 = () => {
    type Props = {
      left: string;
      right: string;
      num: string;
    };
    const ImgPair: React.FC<Props> = (props) => {
      const { left, right, num } = props;
      return (
        <>
          <img
            src={left}
            alt={"row" + num}
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={right}
            alt={"row" + num}
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginBottom: `${imgMargin}%`,
            }}
          />
        </>
      );
    };
    return (
      <PhotoGroup>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <ImgPair left={photo3} right={photo4} num="3" />
          <ImgPair left={photo5} right={photo6} num="5" />
          <ImgPair left={photo7} right={photo8} num="7" />
          <ImgPair left={photo9} right={photo10} num="9" />
          <ImgPair left={photo11} right={photo12} num="11" />
          <ImgPair left={photo13} right={photo14} num="13" />
          <img
            src={photo15}
            alt="photo15"
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginRight: `${imgMargin}%`,
              marginBottom: `${imgMargin}%`,
            }}
          />
          <img
            src={photo16}
            alt="photo16"
            onLoad={didLoadImage}
            style={{
              width: `calc(50% - ${imgMargin / 2}%)`,
              height: "auto",
              marginBottom: `${imgMargin}%`,
            }}
          />
        </div>
      </PhotoGroup>
    );
  };
  // const vh = window.innerHeight;

  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{
          display: isSpinnerShow ? "none" : "block",
          height: "100%",
        }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#45AAB4"
        />
        <Hidden lgUp>
          <div
            style={{
              width: "80%",
              margin: "55px 0px 55px",
              padding: "0px 10% 20px",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <Hidden xsDown>
              <PhotoGroup1 />
              <PhotoGroup2 />
              <PhotoGroup3 />
              <PhotoGroup4 />
            </Hidden>
            <Hidden smUp>
              <PhotoGroup1 />
              <PhotoGroup2 />
              <PhotoGroup5 />
            </Hidden>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div
            style={{
              width: "60%",
              margin: "55px 0px 55px",
              padding: "0px 20% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={titleImg}
              alt="title"
              style={{
                maxWidth: "400px",
                width: "50%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <PhotoGroup1 />
            <PhotoGroup2 />
            <PhotoGroup3 />
            <PhotoGroup4 />
          </div>
        </Hidden>
      </div>
    </>
  );
};

const PhotoGroup = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
`;

export default WorkMedia;

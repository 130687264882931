import React, { useState } from "react";
import BackgroundWrapper from "./sharedComponents/backgroundwrapper";
import LoadingSpinner from "./sharedComponents/loadingSpinner";
import PageHeader from "./sharedComponents/PageHeader";

import header from "../images/about/header_logo_about.png";
import footer from "../images/about/footer_logo_about.png";
import pattern from "../images/about/header_about.png";
import profileImg from "../images/about/about1.svg";
import contactImg from "../images/about/about2.svg";
import blankImg from "../images/about/about3.svg";
import { Hidden } from "@material-ui/core";

const About = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(false);

  const didLoadImage = () => {
    setSpinnerState(false);
  };

  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{
          display: isSpinnerShow ? "none" : "block",
          height: "100%",
        }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#FBB45C"
        />
        <Hidden smUp>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "80%",
              margin: "55px 0px 55px",
              padding: "0px 10% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={profileImg}
              alt="profile"
              style={{
                width: "100%",
                maxWidth: "600px",
                margin: "10% 0 10%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={contactImg}
              alt="contact"
              style={{
                width: "100%",
                maxWidth: "600px",
                margin: "10% 0 10%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={blankImg}
              alt="blank1"
              style={{
                width: "100%",
                maxWidth: "600px",
                margin: "10% 0 10%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={blankImg}
              alt="blank2"
              style={{
                width: "100%",
                maxWidth: "600px",
                margin: "10% 0 10%",
                padding: "0 0 200px",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
          </div>
        </Hidden>
        <Hidden xsDown>
          <div
            style={{
              // height: "calc(100vh - 110px)",
              width: "80%",
              margin: "55px 0px 55px",
              padding: "0px 10% 20px",
              overflowX: "hidden",
            }}
          >
            <img
              src={profileImg}
              alt="profile"
              style={{
                width: "80%",
                maxWidth: "600px",
                margin: "5% 10% 5%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={contactImg}
              alt="contact"
              style={{
                width: "80%",
                maxWidth: "600px",
                margin: "5% 10% 5%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={blankImg}
              alt="blank1"
              style={{
                width: "80%",
                maxWidth: "600px",
                margin: "5% 10% 5%",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
            <img
              src={blankImg}
              alt="blank2"
              style={{
                width: "80%",
                maxWidth: "600px",
                margin: "5% 10% 5%",
                padding: "0 0 200px",
                height: "auto",
                filter: "drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5))",
              }}
            />
          </div>
        </Hidden>
      </div>
    </>
  );
};

export default About;

import React, { useState } from "react";
import styled from "styled-components";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Fab, Hidden } from "@material-ui/core";

import BackgroundWrapper from "./sharedComponents/backgroundwrapper";
import LoadingSpinner from "./sharedComponents/loadingSpinner";
import PageHeader from "./sharedComponents/PageHeader";

import header from "../images/photo/header_logo_photo.png";
import footer from "../images/photo/footer_logo_photo.png";
import pattern from "../images/photo/header_photo.png";

const Photo = () => {
  const [isSpinnerShow, setSpinnerState] = useState<boolean>(false);
  const [loadingCount, setloadingCount] = useState(1);

  const didLoadImage = () => {
    if (loadingCount === 12) {
      setSpinnerState(false);
    } else {
      const newLoadingCount = loadingCount + 1;
      setloadingCount(newLoadingCount);
    }
  };

  return (
    <>
      <LoadingSpinner spinnerState={isSpinnerShow} />
      <div
        style={{
          display: isSpinnerShow ? "none" : "block",
          height: "100%",
        }}
      >
        <BackgroundWrapper />
        <PageHeader
          header={header}
          footer={footer}
          pattern={pattern}
          color="#F9637C"
        />
        <div
          style={{
            width: "80%",
            margin: "55px 0px 55px",
            padding: "20px 10% 20px",
            overflowX: "hidden",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <Hidden smUp>
            {(() => {
              const displayPhotos = [];
              for (let i = 1; i <= 12; i++) {
                displayPhotos.push(
                  <MaskedPhoto
                    src={`photo${i}.jpg`}
                    alt={`photo${i}`}
                    style={{ width: "50%" }}
                    onLoad={didLoadImage}
                  />
                );
              }
              return <>{displayPhotos}</>;
            })()}
          </Hidden>
          <Hidden smDown>
            {(() => {
              const displayPhotos = [];
              for (let i = 1; i <= 12; i++) {
                displayPhotos.push(
                  <MaskedPhoto
                    src={`photo${i}.jpg`}
                    alt={`photo${i}`}
                    style={{ width: "25%" }}
                    onLoad={didLoadImage}
                  />
                );
              }
              return <>{displayPhotos}</>;
            })()}
          </Hidden>
          <Hidden xsDown mdUp>
            {(() => {
              const displayPhotos = [];
              for (let i = 1; i <= 12; i++) {
                displayPhotos.push(
                  <MaskedPhoto
                    src={`photo${i}.jpg`}
                    alt={`photo${i}`}
                    style={{ width: "33%" }}
                    onLoad={didLoadImage}
                  />
                );
              }
              return <>{displayPhotos}</>;
            })()}
          </Hidden>
        </div>
        <Fab
          variant="extended"
          onClick={() => {
            const instaWindow = window.open(
              "https://www.instagram.com/walk_ingegg/"
            );
          }}
          style={{
            position: "fixed",
            bottom: "100px",
            right: "50px",
            textTransform: "none",
          }}
        >
          <InstagramIcon />
          Instagram
        </Fab>
      </div>
    </>
  );
};

const MaskedPhoto = styled.img`
  mask-image: url("photo_mask.png");
  mask-mode: alpha;
  mask-size: cover;
`;

export default Photo;

import React from "react";

type Props = {
  header: string;
  footer: string;
  pattern: string;
};

const HomeHeader: React.FC<Props> = (props) => {
  return (
    <>
      <div style={{}}>
        <div
          style={{
            top: "0",
            width: "100%",
            height: "55px",
            position: "fixed",
            backgroundImage: `url(${props.pattern})`,
            backgroundSize: "auto 100%",
            zIndex: 2,
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "55px",
            position: "fixed",
            backgroundImage: `url(${props.pattern})`,
            backgroundSize: "auto 100%",
            bottom: "0",
            zIndex: 2,
          }}
        ></div>
      </div>
      <img
        src={props.header}
        alt="icon"
        style={{
          height: "55px",
          position: "fixed",
          top: "0",
          left: "10px",
          zIndex: 3,
        }}
      />
      <img
        src={props.footer}
        alt="icon"
        style={{
          height: "55px",
          position: "fixed",
          bottom: "0",
          right: "10px",
          zIndex: 3,
        }}
      />
    </>
  );
};

export default HomeHeader;

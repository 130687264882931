import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

type Props = {
  picSrc: string;
  stubSrc: string;
  alt: string;
  link: string;
  onLoad?: () => void;
};

const WorkTicket: React.FC<Props> = (props) => {
  const { picSrc, stubSrc, alt, link, onLoad } = props;
  const history = useHistory();
  const handleToPage = () => {
    history.push(link);
  };

  return (
    <>
      <Wrapper>
        <img
          className="static"
          src={picSrc}
          alt={alt}
          onClick={handleToPage}
          onLoad={onLoad}
        />
        <img
          className="rotate"
          src={stubSrc}
          alt={alt}
          onClick={handleToPage}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  filter: drop-shadow(6px 6px 1px rgba(0, 0, 0, 0.3));

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;

  .static {
    width: auto;
    height: 100%;
  }

  .rotate {
    width: auto;
    height: 100%;
    right: 0;
  }

  :hover {
    cursor: pointer;
  }

  :hover .rotate {
    animation: t2 0.1s ease-out;
    animation-fill-mode: forwards;
    transform-origin: left bottom;
  }

  @keyframes t2 {
    0% {
      transform: rotate(0deg) translateX(0%);
    }
    100% {
      transform: rotate(6deg) translateX(30%);
    }
  }
`;

export default WorkTicket;
